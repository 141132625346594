<template>
  <div class="uk-width-1-1">
    <div class="uk-background-default uk-padding-small">
      <div class="uk-form-stacked">
        <div class="uk-grid uk-child-width-1-3" data-uk-grid>
          <div>
            <label class="uk-form-label">Typ</label>
            <v-select
              :options="[{label: 'Neueste Beiträge', value: 'latest'}, {label: 'Zufällige Beiträge', value: 'random'}]"
              placeholder="Bitte wählen"
              v-model="form.type"
              :reduce="type => type.value"
              :clearable="false"
            ></v-select>
          </div>
          <div>
            <label class="uk-form-label">Anzahl</label>
            <input class="uk-input" type="text" v-model="form.count" />
          </div>
        </div>
        <div class="uk-grid uk-child-width-1-1" data-uk-grid>
          <div>
            <label>
              <input type="checkbox" class="uk-checkbox" v-model="form.show_full"> Zeige kompletten Beitrag
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import vSelect from 'vue-select'
export default {
  props: {
    data: {
      required: true,
      type: Object
    }
  },
  components: {
    vSelect
  },
  data () {
    return {
      form: {}
    }
  },
  mounted () {
    this.form = this.data
  }
}
</script>
